import React from "react"
import NavBar from "../../components/header/nav-main"
import Helmet from "react-helmet"
import "../../css/hackathon.css"

const Go = () => {
  return (
    <>
      <Helmet>
        <meta name="go-import" content="solace.dev/go/messaging git https://github.com/SolaceProducts/pubsubplus-go-client"></meta>
        <meta name="go-source" content="solace.dev/go/messaging https://github.com/SolaceProducts/pubsubplus-go-client https://github.com/SolaceProducts/pubsubplus-go-client/tree/master{/dir} https://github.com/SolaceProducts/pubsubplus-go-client/tree/master{/dir}/{file}#L{line}"></meta>
        <meta name="robots" content="noindex"></meta>
        <meta http-equiv="refresh" content="0;url=https://docs.solace.com/API-Developer-Online-Ref-Documentation/go/"></meta>
      </Helmet>
      <header>
        <NavBar/>
      </header>
    </>
  )
}

export default Go
